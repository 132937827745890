<template>
    <div class="index-container" ref="indexAll">
        <indexCarousel></indexCarousel>
        <a class="gogogo" @click="goAssignBlock('block'+ 0,50)">
            <img class="carousel-img1" src="../assets/image/index/01-1鼠标.svg"/>
            <img class="carousel-img2" src="../assets/image/index/01-1向下.svg"/>
            <div class="button-box">由此开始</div>
        </a>
        <div class="container-6" ref="block0">
            <div class="container-title" style="padding-top: 7.8rem;">行业方案</div>
            <div class="container-content" style="padding-top: 1rem;">比孚聚焦于快消零售，医疗健康两大行业</div>
            <div class="big-box-1" style="margin-top:3.9rem">
                 <div class="box-1">
                    <div class="box-content">
                        <div class="box-content-title">快消零售</div>
                        <div class="box-content-content">比孚与快消零售行业内众多优秀厂商共建长期稳定合作关系，基于多年的行业咨询和实施经验，积累了完整的数智供应链解决方案
                        ，帮助合作伙伴建立标准化管理体系和流程，优化供应链组织结构，为业务转型提供全面支持。</div>
                        <button class="box-content--botton"  @click="jumpRout('/industry-scheme','2')">了解详情<span></span></button>
                    </div>
                    <div class="box-content">
                        <img class="box-content-img" src="../assets/image/index/01-1快消零售.png"/>
                    </div>
                </div>
            </div>
            <div class="big-box-1" style="margin-top:3rem;">
              <div class="box-1" >
                <div class="box-content">
                    <img class="box-content-img" src="../assets/image/index/01-1医疗健康.png"/>
                </div>
                <div class="box-content">
                    <div class="box-content-title">医疗健康</div>
                    <div class="box-content-content">比孚与IQVIA展开深度合作，形成了全方位战略合作共赢模式，凭借医药生物行业内成熟的产品及解决方案，
                        帮助企业完成实施及落地，从而加速实现业务转型和获取价值。</div>
                    <button class="box-content--botton" @click="jumpRout('/medical-health','2')">了解详情<span></span></button>
                </div>
            </div>
            </div>

        </div>
        <div class="container-7" ref="block3">
            <div class="container-title" style="padding-top: 7.8rem;">产品方案</div>
            <!-- <div class="container-content" style="padding-top: 1rem;">Vticket</div> -->
            <div class="big-box-2" style="margin-top:3.9rem">
                 <div class="box-1">
                    <div class="box-content">
                        <div class="box-content-title">
                           <img class="box-content-title-img" src="@/assets/image/index/vticket.svg" alt="">
                        </div>
                        <div class="box-content-content">Vticket 是一款“不一样的”IT服务管理（ITSM）平台。包含20余项功能模块，融合了100多项创新功能，依托“以图传意，化繁为简”的核心理念，通过“可视化”、“协同化”和“智能化”，给用户带来轻松的IT服务体验的同时，提高IT团队的工作效率，持续巩固和改进企业数字化的成果。</div>
                        <button class="box-content--botton2"  @click="handleJumpTo('vticket')">了解详情<span></span></button>
                    </div>
                    <div class="box-content">
                        <img class="box-content-img" src="../assets/image/index/index-Vticket.png"/>
                    </div>
                </div>
            </div>
            <div class="big-box-2" style="margin-top:3rem;">
              <div class="box-1" >
                <div class="box-content">
                    <img class="box-content-img" src="../assets/image/index/index-Vproject.png"/>
                </div>
                <div class="box-content">
                    <div class="box-content-title">
                      <img class="box-content-title-img" src="@/assets/image/index/vproject.svg" alt="">
                    </div>
                    <div class="box-content-content">Vproject 是一款“更聪明的”IT项目管理平台。最大亮点在于其高度的定制化和灵活性。无论是小型团队还是大型企业，Vproject都能适应各种需求，提供个性化的工作流程和视图，包括看板、甘特图、列表视图等。此外，Vproject的AI智能助理和自动化功能，进一步简化了日常任务和流程管理，使项目管理变得更加智能和高效。</div>
                    <button class="box-content--botton2" @click="handleJumpTo('vproject')">了解详情<span></span></button>
                </div>
            </div>
            </div>
        </div>
        <div class="container-2" ref="block1">
            <div class="container-title" style="padding-top: 6rem;">
              <div class="product-case-header">
                <div class="product-case-title">精选案例</div>
                <div class="product-case-tabs">
                  <div
                      v-for="(item, index) in productCaseTabs"
                      class="product-case-tab-item"
                      :class="item.value === productCaseTabValue ? 'active' : ''"
                      :key="index"
                      @click="productCaseTabValue = item.value"
                    >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
            <div class="container-content" style="padding-top: 1.1rem;height: 8.9rem">帮助企业以更低成本更好效果更短时间内实现业务流程的数字 转型，提升业务效率，减少人工，优化成本</div>
            <div class="box-2" v-show="productCaseTabValue === 'productBoutique'">
                <div class="swiper-container product-swiper-container-1" id="banner">
                    <div class="swiper-wrapper" id="banner-list">
                        <div class="swiper-slide" v-for="item in box2ImageList" :key="item.index">

                            <div class="img-item" @click="jumpRout(item.route,'3')">
                                <!-- <div :class="{'box-left': item.index === (box2Index+5)%7}">{{item.index}}</div> -->
                                <div class="img-container">
                                    <img class="img-item-img" :src="item.urlImg" @click="showindex(item.index)"></img>
                                </div>

                                <div class="img-item-title">{{item.title}}</div>
                                <div class="img-item-content">{{item.content}}</div>
                                <div class="img-item-more">查看全部</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="swiper-button-prev"  @click="next"><div class="swiper-line-left"></div></div>
                    <div class="swiper-button-next"  @click="prev"><div class="swiper-line-right"></div></div> -->
                    <div class="swiper-button-prev"  @click="next"><img src="../assets/image/left.png"/></div>
                    <div class="swiper-button-next"  @click="prev"><img src="../assets/image/right.png"/></div>
                </div>
            </div>

            <div class="box-2" v-show="productCaseTabValue === 'productCase'">
                <div class="swiper-container product-swiper-container-2" id="banner2">
                    <div class="swiper-wrapper" id="banner-list2">
                        <div class="swiper-slide" v-for="item in productCaseImage" :key="item.index">

                            <div class="img-item" @click="jumpRout(item.route,'3')">
                                <!-- <div :class="{'box-left': item.index === (box2Index+5)%7}">{{item.index}}</div> -->
                                <div class="img-container">
                                    <img class="img-item-img" :src="item.urlImg" @click="showindex2(item.index)"></img>
                                </div>

                                <div class="img-item-title">{{item.title}}</div>
                                <div class="img-item-content">{{item.content}}</div>
                                <div class="img-item-more">查看全部</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="swiper-button-prev"  @click="next"><div class="swiper-line-left"></div></div>
                    <div class="swiper-button-next"  @click="prev"><div class="swiper-line-right"></div></div> -->
                    <div class="swiper-button-prev"  @click="next2"><img src="../assets/image/left.png"/></div>
                    <div class="swiper-button-next"  @click="prev2"><img src="../assets/image/right.png"/></div>
                </div>
            </div>

        </div>
        <div class="container-3" ref="block2">
            <div class="container-title" style="padding-top: 7.8rem;">技术服务</div>
            <div class="container-content" style="padding-top: 1.1rem;">提供从咨询设计到开发落地运维的完整技术服务</div>
            <el-carousel indicator-position="outside" height="65rem" :autoplay="false">
              <el-carousel-item v-for="item in box3ComputedList" :key="item.index">
                <div class="box-3">
                  <div class="box3-card" v-for="(child, childIndex) in item" :key="childIndex">
                      <img :src="child.image" class="box3-card-img" alt="">
                      <div class="box3-card-title">{{ child.title }}</div>
                      <div class="box3-card-content">{{  child.content  }}</div>
                      <div class="box3-card-more" @click="jumpRout(child.route, child.dataindex, child.isLink)"><div class="showmore-box"><i class="el-icon-right icon-move" style="margin-top:0.5rem;margin-left: 2.4rem;"></i></div><span>了解详情</span></div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
        </div>
        <div class="container-4">
            <div class="container-title" style="padding-top: 6rem;">新闻资讯</div>
            <el-carousel indicator-position="outside" height="34rem">
                    <el-carousel-item v-for="item in box4List" :key="item.index">
                        <div class="container4-carousel">
                            <img :src="item.url" class="container4-carousel-img">
                            <div class="box4">
                                <div class="container4-carousel-title">{{item.title}}</div>
                                <div class="container4-carousel-date">{{item.date}}</div>
                                <div class="container4-carousel-content">{{item.content}}</div>
                                <div class="container4-carousel-more" @click="jumpNews(item.id,'5')"><div class="showmore-box"><i class="el-icon-right icon-move" style="margin-top:0.5rem;margin-left: 2.4rem;"></i></div><span>阅读更多</span></div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
        </div>
        <div class="container-5" style="margin-bottom: 7.7rem;">
            <div class="container-title" style="padding-top: 8rem;">合作伙伴</div>
            <div class="box5">
                <div class="title">客户摘选</div>
                <img src="../assets/image/index/01-1客户摘选.png" class="box5-img">
                <div class="customer-extract" @click="jumpRout('/aboutus','6')" ></div>
                <div class="title" style="margin-top:4rem">合作伙伴</div>
                <img src="../assets/image/index/01-1合作伙伴.png" class="box5-img">
                <div class="customer-extract" @click="jumpRout('/aboutus','6')" ></div>
            </div>
        </div>
        <div class="container-2" style="height: 51.5rem;display: block;">
            <div class="container-title" style="padding-top: 7.8rem;">联系我们</div>
            <div class="service-box">
                <!-- <div class="service-title1">服务<div class="service-title2">1000+</div>项目</div> -->
                <div class="service-title3">让我们了解您的需求，给您量身定制解决方案!</div>
                <div class="service-title4" style="cursor:pointer" @click="jumpRout('/joinus','7')">查找最优化方案？ 现在联系我们<img style="margin-left:2rem" src="../assets/image/index/右箭头.svg"/></div>
            </div>
            <img src="../assets/image/index/01-1地图.png" class="map-img"/>
        </div>
    </div>
</template>

<script>
import indexCarousel from '../components/index-carousel/index-carousel.vue'
import store from '@/plugins/store';
import { mapActions } from 'vuex';
import { getAction } from '@/utils/request.js';
import Swiper from "swiper";
export default {
    name: 'BizfocusPagesIndex',
    components:{indexCarousel},
    metaInfo:{
        meta:[
        { name:'keywords', content:'比孚,上海比孚,比孚信息,比孚信息科技,数字化转型,数据湖仓技术,SAP实施运维,定制开发运维,亚马逊合作伙伴,SAP全球认证,快消零售,医疗健康,制造行业,企业数字化解决方案,饮料供应链,供应链管理平台' },
        ]
    },
    data() {
        return {
            swiperOption: {
              slidesPerView: 3,
              spaceBetween: 30,
              pagination: {
                el: '.swiper-pagination',
                clickable: true
              }
            },
            freeMode: true,
            mySwiper: null,
            box2Index: 0,
            box2arrow:'right',
            mySwiper2: null,
            box2Index2: 0,
            box2arrow2:'right',
            headImgList:[
                {
                    index: 1,
                    url:require("../assets/image/index/banner01.png")
                },
                {
                    index: 2,
                    url:require("../assets/image/index/banner02.png")
                },
                {
                    index: 3,
                    url:require("../assets/image/index/banner03.png")
                }
            ],
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'中国某知名调味品生产公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            box4List:[
            ],
            box3List: [
              {
                image: require('../assets/image/index/01-1数据湖仓技术.png'),
                title: '数据湖仓技术',
                content: '比孚科技作为Amazon高级咨询合作伙伴，致力于帮助企业构建数据湖，实现核心流程数字化、自助式商业智能、BI移动化等服务，推动企业数字化转型。',
                route: '/data-warehouse',
                dataindex: '4',
              },
              {
                image: require('../assets/image/index/SAP实施及运维.png'),
                title: 'SAP实施及运维',
                content: '作为SAP全球认证的合作伙伴，比孚凭借着对信息技术趋势的洞察，丰富的行业经验，以及全方位的服务，协助客户进行数字化转型、云部署和技术创新……',
                route: 'http://www.asialink.sh.cn/pc/home.html#',
                isLink: true,
              },
              {
                image: require('../assets/image/index/01-1定制开发及运维.png'),
                title: '定制开发及运维',
                content: '比孚科技凭精湛的研发技术，以及在制造，零售，医药行业数字化转型上积累的丰富经验， 可为客户提供各类业务系统的定制化开发服务。',
                route: '/custom-development',
                dataindex: '4',
              },
              {
                image: require('../assets/image/index/index-technology-service.png'),
                title: 'IT 服务平台',
                content: '画简信息科技致力于为企业和个人提供简单、高效、实用的IT服务及项目研发管理解决方案，帮助客户在数字化时代中快速响应市场变化，实现业务价值的最大化。',
                route: '/service-system',
                dataindex: '3',
              },
            ],
            productCaseTabs: [
              {
                label: '精选案例',
                value: 'productBoutique'
              },
              {
                label: '产品案例',
                value: 'productCase'
              },
            ],
            productCaseTabValue: 'productBoutique',
            productCaseImage: [
              {
                index: 0,
                urlImg: require("../assets/image/productCase/index-technology.png"),
                title: '某知名传媒科技公司',
                content: '私有化部署，自动生成纪要及任务',
                route: '/produt-case-technology'
              },
              {
                index: 1,
                urlImg: require("../assets/image/productCase/index-metallurgy.png"),
                title: '某大型国有冶金集团',
                content: '智能派单，识别关键用户和问题',
                route: '/produt-case-metallurgy'
              },
              {
                index: 2,
                urlImg: require("../assets/image/productCase/index-pharmaceutical.png"),
                title: '某大型医药企业',
                content: '服务门户，自动化的运维服务流程',
                route: '/produt-case-pharmaceutical'
              },
              {
                index: 3,
                urlImg: require("../assets/image/productCase/index-catering.png"),
                title: '某全国连锁餐饮企业',
                content: 'AI智能客服，7*24小时即时响应',
                route: '/produt-case-catering'
              },
              {
                index: 4,
                urlImg: require("../assets/image/productCase/index-retail.png"),
                title: '某大型零售连锁企业',
                content: '远程协助，迅速定位并解决故障',
                route: '/produt-case-retail'
              },
            ]

        };
    },
    created() {
        this.box4List.reverse()
    },
    mounted() {
        this.init()
        this.activeIndex = '1'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        this.mySwiper = new Swiper(".product-swiper-container-1", {

            centeredSlides: true,

            // slidesPerView: 1.1, //数值越大，两侧显示的图越大

            // loop: true,

            autoplay: 3000,
            cache: false,
            effect : 'coverflow',
            slidesPerView: 3,
            initialSlide :3,//默认第四页
            centeredSlides: true,
                coverflow: {
                rotate: 30,
                stretch: 10,
                depth: 60,
                modifier: 2,
                slideShadows : true
            },
            // observer: true, // 改变swiper样式时，自动初始化swiper
	        // observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
            spaceBetween : 10,
            // centeredSlidesBounds: true,
            slideDuplicateClass : 'my-slide-duplicate',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
            },

        })
        this.mySwiper2 = new Swiper(".product-swiper-container-2", {

centeredSlides: true,

// slidesPerView: 1.1, //数值越大，两侧显示的图越大

// loop: true,

autoplay: 3000,
cache: false,
effect : 'coverflow',
slidesPerView: 3,
initialSlide :3,//默认第四页
centeredSlides: true,
    coverflow: {
    rotate: 30,
    stretch: 10,
    depth: 60,
    modifier: 2,
    slideShadows : true
},
// observer: true, // 改变swiper样式时，自动初始化swiper
// observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
spaceBetween : 10,
// centeredSlidesBounds: true,
slideDuplicateClass : 'my-slide-duplicate',
navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
},
on: {
},

})
        this.$refs['block0'].childNodes[0].classList.add('can-no-show')
        this.$refs['block0'].childNodes[1].classList.add('can-no-show')
        this.$refs['block0'].childNodes[2].classList.add('can-no-width')
        this.$refs['block0'].childNodes[3].classList.add('can-no-width')
        this.$refs['block1'].childNodes[0].classList.add('can-no-show')
        this.$refs['block1'].childNodes[1].classList.add('can-no-show')
        this.$refs['block2'].childNodes[0].classList.add('can-no-show')
        this.$refs['block2'].childNodes[1].classList.add('can-no-show')
        this.$refs['block2'].childNodes[2].classList.add('can-no-height')
        // 监听滚动事件
        // window.addEventListener('scroll', this.fiexdNav)
        // window.addEventListener('mousewheel', this.fiexdNav,true)
        this.$refs.indexAll.addEventListener('mousewheel', this.fiexdNav,true)

        // this.$nextTick(function(){
        //     window.addEventListener('mousewheel', this.fiexdNav)
        // });
    },
    // updated() {
    //     window.addEventListener('mousewheel', this.fiexdNav)
    // },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        console.log('destory')
        this.$refs.indexAll.removeEventListener('mousewheel', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        async init(){
            const res = await getAction(`/wp/wp-json/wp/v2/posts?categories=5`);
            if(res){
                res.map((element,index) => {
                    let oneNew = {}
                    oneNew.index = index;
                    oneNew.title = element.title.rendered
                    let date = element.date.split('T')
                    let newDate = date[0].split('-')[0] + '/' + date[0].split('-')[1] + '/' + date[0].split('-')[2]
                    oneNew.date = newDate
                    oneNew.content = element.content.rendered.split('<p>')[1].split('</p>')[0]
                    oneNew.route = `/news-detail`
                    oneNew.id = element.id
                    this.box4List.push(oneNew)
                    getAction(`/wp/wp-json/wp/v2/media/${element.featured_media}`).then((res2) => {
                        oneNew.url = res2.guid.rendered
                        oneNew.url = oneNew.url.replace("http://69.230.196.6:8088", "https://www.bizfocus.cn/wp")
                    });

                });
                setTimeout(()=>{
                   this.box4List.sort((function(a,b) {
                        return a.index-b.index
                    }))
                    this.box4List = this.box4List.slice(0, 5)
                },500)
            }
        },
        gotoOutLine(){
            window.location.href = 'http://www.asialink.sh.cn/pc/home.html#'
        },
        jumpRout(route,index, isLink){
            window.onmousewheel = document.onmousewheel = null;
            if (isLink) {
              route && window.open(route, '_blank');
              return;
            }
            this.activeIndex = index
            this.newsIndex = false
            localStorage.setItem('headId',index);
            localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push(route)
            this.activeIndex = localStorage.getItem('headId') || '1';
            this.refreshIndexInfo({
                data:this.activeIndex ,
                news:this.newsIndex
            });
        },
        jumpNews(route,index){
            window.onmousewheel = document.onmousewheel = null;
            this.activeIndex = index
            this.newsIndex = false
            localStorage.setItem('headId',index);
            localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push({
                path:'/news-detail',
                query:{
                    id:route
                }
            })
            this.activeIndex = localStorage.getItem('headId') || '1';
            this.refreshIndexInfo({
                data:this.activeIndex ,
                news:this.newsIndex
            });
        },
        next(){
            this.box2arrow = 'right'
            this.box2Index = (this.mySwiper.realIndex+6)%7
        },
        prev(){
            this.box2arrow = 'left'
            this.box2Index = (this.mySwiper.realIndex+1)%7
        },
        showindex(value){
        },
        next2(){
            this.box2arrow2 = 'right'
            this.box2Index2 = (this.mySwiper2.realIndex+6)%7
        },
        prev2(){
            this.box2arrow2 = 'left'
            this.box2Index2 = (this.mySwiper2.realIndex+1)%7
        },
        showindex2(value){
        },
        onSlideChange(){
          },
        // box2ImageIncrease(){
        //     const data = [];
        //     const listName = this.productCaseTabValue === 'productBoutique' ? 'box2ImageList' : 'productCaseImage';
        //     (this[listName] || [])?.forEach((item, index) => {
        //         if(index > 0){
        //             data[index - 1] = item
        //         }
        //     })
        //     data[data.length] = this[listName]?.[0]
        //     this[listName] = data
        // },
        // box2ImageDecrease(){
        //     const data = [];
        //     const listName = this.productCaseTabValue === 'productBoutique' ? 'box2ImageList' : 'productCaseImage';
        //     data[0] = this[listName]?.[this[listName].length-1]
        //     for(let i = this[listName]?.length -1; i > 0; i--){
        //         data[i] = this[listName]?.[i-1]
        //     }
        //     this[listName] = data

        // },
        goAssignBlock(el, speed) {
            let _this = this;
            let windowH = window.innerHeight; //浏览器窗口高度
            let h = this.$refs[el].offsetHeight; //模块内容高度
            let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
            let top = t - (windowH - h) / 2; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop; //滚动条距离顶部高度
            let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
            let requestId;
            //采用requestAnimationFrame，平滑动画
            function step() {
                //判断让滚动条向上滚还是向下滚
                if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
                } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop - speed);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
                }
            }
            this.$refs['block0'].childNodes[0].classList.remove('can-no-show')
            this.$refs['block0'].childNodes[1].classList.remove('can-no-show')
            this.$refs['block0'].childNodes[2].classList.remove('can-no-width')
            this.$refs['block0'].childNodes[3].classList.remove('can-no-width')
            window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        // getData () {
        //     this.navOffsetTop = document.querySelector('.head-list').offsetTop - 100
        // },
        fiexdNav (e) {
            const block0 = this.$refs['block0'].offsetTop
            const block1 = this.$refs['block3'].offsetTop + 600
            const block2 = this.$refs['block1'].offsetTop + 200
            //行业方案
            if(e.pageY > 100){
                this.$refs['block0'].childNodes[0].classList.remove('can-no-show')
                this.$refs['block0'].childNodes[1].classList.remove('can-no-show')
                this.$refs['block0'].childNodes[2].classList.remove('can-no-width')
                this.$refs['block0'].childNodes[3].classList.remove('can-no-width')
            }else{
                // this.$refs['block0'].childNodes[0].classList.add('can-no-show')
                // this.$refs['block0'].childNodes[1].classList.add('can-no-show')
                // this.$refs['block0'].childNodes[2].classList.add('can-no-width')
                // this.$refs['block0'].childNodes[3].classList.add('can-no-width')
            }
            //精选案例
            if(e.pageY > block1){
                this.$refs['block1'].childNodes[0].classList.remove('can-no-show')
                this.$refs['block1'].childNodes[1].classList.remove('can-no-show')
            }else{
                // this.$refs['block1'].childNodes[0].classList.add('can-no-show')
                // this.$refs['block1'].childNodes[1].classList.add('can-no-show')
            }
            //技术服务
            if(e.pageY > block2 - 100){
                this.$refs['block2'].childNodes[0].classList.remove('can-no-show')
                this.$refs['block2'].childNodes[1].classList.remove('can-no-show')
                this.$refs['block2'].childNodes[2].classList.remove('can-no-height')
            }else{
                // this.$refs['block2'].childNodes[0].classList.add('can-no-show')
                // this.$refs['block2'].childNodes[1].classList.add('can-no-show')
                // this.$refs['block2'].childNodes[2].classList.add('can-no-height')
            }
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop; //滚动条距离顶部高度
                if((e.deltaY>0 || scrollTop < 70)){
                    this.refreshIndexInfo({
                        isIndex: 0
                    });
                }else if(e.deltaY<0){
                    this.refreshIndexInfo({
                        isIndex: 1
                    });
                }
            // window.onmousewheel= document.onmousewheel=(e)=>{

            // }
        },
        handleJumpTo(type) {
          const URL_MAP = {
            vticket: 'https://visualticket.cn/',
            vproject: 'https://visualticket.cn/',
          }
          if (!type) return;
          const url = URL_MAP[type];
          if (!url) return;
          url && window.open(url, '_blank');
        }
    },
    computed: {
      box3ComputedList() {
        const list = this.box3List.reduce((res, item, index) => {
          const newIndex = index / 3;
          if (Number.isInteger(newIndex)) {
            res[newIndex] = (res[newIndex] || []).concat([item])
          } else {
            const calcIndex = Math.floor(newIndex)
            res[calcIndex] = (res[calcIndex] || []).concat([item])
          }
          return res
        }, [])
        return list
      }
    },
    watch: {
      productCaseTabValue: {
        handler(newVal) {
          if (newVal === 'productBoutique') {
            setTimeout(() => {
                this.mySwiper.update();
            }, 50);
          } else if (newVal === 'productCase') {
            setTimeout(() => {
                this.mySwiper2.update();
            }, 50);
          }
        },
        immediate: false,
      }
    }
};
</script>

<style lang="less" scoped>
.can-no-show{
    opacity: 0;
    transform: translateY(1rem);
}
.can-no-width{
    // opacity: 0;
    width: 0rem;
}
.can-no-height{
    // opacity: 0;
    height: 0rem;
}
.my-slide-duplicate{
    border: 1px solid #f00;
}
.img-trangle1{
    position: absolute;
    width: 0;
    height: 0;
    border-top:0px solid transparent;
    border-left: 0px solid #000;
    border-right: 27.7rem solid #F8F8F8 ;
    border-bottom: 5.3rem solid transparent;
}
.img-trangle2{
    position: absolute;
    width:25rem;
    height:24.9rem;
    margin: 5.3rem 0 0 1rem;
    width: 0;
    height: 0;
    border-top:0px solid transparent;
    border-left: 0px solid #000;
    border-right: 25rem solid #F8F8F8 ;
    border-bottom: 2rem solid transparent;
}
.img-trangle3{
    position: absolute;
    width:25rem;
    height:24.9rem;
    margin: 5.3rem 0 0 1rem;
    width: 0;
    height: 0;
    border-top:0px solid transparent;
    border-left: 25rem solid #F8F8F8;
    border-right: 0rem solid transparent ;
    border-bottom: 2rem solid transparent;
}
.img-trangle4{
    position: absolute;
    width: 0;
    height: 0;
    margin: 0rem 0 0 0rem;
    border-top:0px solid transparent;
    border-left: 27.7rem  solid #F8F8F8;
    border-right: 0px solid transparent ;
    border-bottom: 5.3rem solid transparent;
}
.swiper-container{
    --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
    --swiper-navigation-color: #000000;/* 单独设置按钮颜色 */
    --swiper-navigation-size: 30px;/* 设置按钮大小 */
  }
  .swiper-slide{
    height: 35rem;
    overflow: hidden;
  }
  .swiper-slide-active:hover>.img-item .img-item-img{
    transform: scale(1.2);
    transition: all .5s;
  }
  .swiper-slide-active:hover{
    // filter: blur(0) drop-shadow(3px 3px 3px #888888);
    border-radius: 0;
    box-shadow: 0px 0px 30px rgba(0,0,0,0.15);
    transition: 2s;
  }
  .swiper-slide-active{
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 0px rgba(231,231,231,0.5);
    height: 46rem;
    transition: all 2s;
    cursor: url('../assets/image/search.png'),pointer;
    .img-container{
        width: 25rem;
        height: 30rem;
        margin: auto;
        margin-top: 2.1rem;
        overflow: hidden;
    }
    .img-item-img{
        width: 25rem;
        height: 30rem;
        transition: all 1s;
    }
    .img-item-title{
        /* width: 8rem;
        height: 2rem; */
        text-align: justify;
        opacity: 1.0;
        margin: 2.3rem 0 0 10rem;
        font-size: 2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;
        transition: all 2s;
    }
    .img-item-title:hover{}
    .img-item-content{
        width: 25rem;
        height: 5.5rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 2rem;

        text-align: justify;
        margin: 0.2rem 0 0 10rem;
    }
    .img-item-more{
        height: 3rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 3rem;

        text-align: justify;
        margin: -1.5rem 0 0 10rem;
        cursor: pointer;
    }
  }
  .swiper-button-prev {

    background-size: 4.5rem 4.5rem;
    width: 4.5rem;
    height: 4.5rem;
    left:0%;
    top: 50%;
    border: 0px solid #666666;
    border-radius: 100rem;
    margin-left: 39.5rem;
    margin-top: 12rem;
    display: flex;
    opacity: 0.5;
    transition: all .5s;
}
.swiper-button-prev:hover {

    transform: scale(1.1);
    transition: all .5s;
}
.swiper-button-prev:after{
    // margin-left: -2rem;
    // transform: scale(0.5);
    display: none;
}
.swiper-button-next{

    background-size: 4.5rem 4.5rem;
    width: 4.5rem;
    height: 4.5rem;
    border: 0px solid #666666;
    border-radius: 100rem;
    margin-right: 38rem;
    margin-top: 12rem;
    opacity: 0.5;
    transition: all .5s;
}
.swiper-button-next:hover {

transform: scale(1.1);
transition: all .5s;
}
.swiper-button-next:after{
    // margin-left: -2rem;
    // transform: scale(0.5);
    // margin-left: -0.8rem;
    display: none;
}
.swiper-line-left{
    position: absolute;
    width: 2.5rem;
    border: 0px solid #666666;
    margin-left: 0.7rem;
    // margin-top: 0.05rem;
}
.swiper-line-right{
    width: 2.5rem;
    border: 0px solid #666666;
    margin-left: 0.7rem;
}
.service-box{
    width: 139rem;
    margin: auto;
    margin-top:7rem;
}
.service-title1{
    display: flex;
    height: 3.9rem;
    font-size: 3.2rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 3.2rem;
    .service-title2{
        color: #11A84F;
    }
}
.service-title3{
    height: 3.9rem;
    font-size: 3.2rem;
    font-family: 'CN_Normal';
    text-align: justify;
    font-weight: 400;
    color: #666666;
    line-height: 3.2rem;
    margin-top: 1.1rem;
}
.service-title4{
    height: 3.6rem;
    font-size: 2.2rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #11A84F;
    line-height: 3.3rem;
    text-align: justify;
    margin-top: 12.3rem;
}
.map-img{
    width: 90rem;
    height: 45.1rem;
    margin: -40rem 0 0 50rem;
    z-index: -1;
}
.carousel-img1{
    position: absolute;
    z-index: 100;
    width: 3.8rem;
    height: 3.8rem;
    margin-top: -20rem;
    margin-left: -1rem;
    cursor: pointer;
  }
  .carousel-img2{
    position: absolute;
    z-index: 101;
    margin-left: -0.1rem;
    margin-top: -15rem;
    width: 2.1rem;
    height: 2.1rem;
    cursor: pointer;
  }
  .customer-extract{
    width: 27rem;
    height: 10rem;
    margin-top: -10rem;
    margin-left: 138rem;
    position: absolute;
    cursor: pointer;
  }

  .gogogo{
    position: absolute;
    animation-name: likes; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 1s; // 动画完成时间
    .button-box{
        position: absolute;
        z-index: 101;
        margin-top: -23.5rem;
        margin-left: -4rem;
        font-size: 1.8rem;
        width: 10rem;
        // font-weight: bolder;
        color: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 10rem;
        overflow: hidden;
        transform: scale(0);
    }
  }
  .gogogo:hover>.button-box{
    transform: scale(1.0);
    transition: all 1s;
  }
  @keyframes likes {
		    0%{
                position: absolute;
                z-index: 100;
                transform: translateY(1rem);
		   }
		   50%{
            position: absolute;
            z-index: 100;
            transform: translateY(2rem);
		   }
		   100%{
              position: absolute;
              z-index: 100;
		      transform: translateY(1rem);
		   }
    }
  .icon-move{
    animation-name: iconMove; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 2s; // 动画完成时间
  }
  @keyframes iconMove {
		  0%{
            transform: translateX(0rem);
		   }
		   50%{
            transform: translateX(0.5rem);
		   }
		   100%{
		      transform: translateX(0rem);
		   }
    }

  .product-case-header {
    display: flex;
    align-items: center;
  }

  .product-case-tabs {
    display: flex;
    align-items: center;
    margin-left: 7rem;
  }

  .product-case-tab-item {
    font-weight: 500;
    font-size: 1.6rem;
    color: #999999;
    line-height: 2.4rem;
    cursor: pointer;
    padding: 1rem 3rem;
    border-radius: 1.8rem;
    background-color: #F1F1F1;

    &.active {
      color: #11A84F;
      background-color: #E1F0E7;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
</style>
