<template>
    <div>
        <el-carousel :interval="7000" arrow="always" class="head-picture" trigger="click" height="98rem" width="185rem" @change="changImg" @next="nextImg">
            <el-carousel-item v-for="item in headImgList" :key="item.index"  @click.native="jumpRout(item.route,item.dataindex, item.isLink)" >
                <img :class="{'carousel-img':true ,'carousel-img-small':flag}" :src="item.url"  @load="hasLoaded"/>
                <!-- <img :class="{'carousel-img':true ,'carousel-img-small':flag}" v-lazy="item.url" :key="item.url"/> -->
                <!-- <img id="carousel-img1" class="carousel-img" :src="item.url"/> -->
                <div class="carousel-head-content" style="white-space: pre-wrap;">
                <!-- <div :class="{'carousel-head-content':true ,'carousel-title-small':flag}" style="white-space: pre-wrap;"> -->
                    <div :class="{'title1':true ,'carousel-title-small':flag}">{{item.title1}}</div>
                    <div :class="{'title2':true ,'carousel-title-small2':flag}">{{item.title2}}</div>
                    <div :class="{'title3':true ,'carousel-title-small3':flag}">{{item.title3}}</div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesIndexCarousel',

    data() {
        return {
            headImgList:[
                {
                    index: 1,
                    url:require("../../assets/image/index/smallbanner01.jpg"),
                    title1:'让我们携手加速技术突破，构建美好未来',
                    title2:'推动企业数字化重塑和创新',
                    title3:'我们提供一流的数据湖仓技术，SAP实施和定制开发及运维服务;\n在快消零售及医疗健康行业，为客户提供专业且快速见效的解决方案。',
                    route:'/aboutus',
                    dataindex:'6'
                },
                {
                    index: 2,
                    url:require("../../assets/image/index/01-1大banner02.png"),
                    title1:'透明・高效・稳定',
                    title2:'数字化生产管理平台',
                    title3:'以设备、能耗、人员透明化管理为核心，降低运营成本和提高生产力为宗旨，\n打造智能工厂数字化生产管理平台，\n帮助厂商按需定制生产所需应用场景，助力用户生产管理的数字化转型。',
                    route:'/selected-line',
                    dataindex:'3'
                },
                {
                    index: 3,
                    url:require("../../assets/image/index/01-1大banner03.png"),
                    title1:'原料仓储数字化解决方案',
                    title2:'布局原料赋码、助力品质生产',
                    title3:'通过原料流转过程管控与质量追溯管理，实现企业上下游信息无缝衔接，\n减少非必要作业流转的沟通，\n建立生产力报告数据基础，布局原料赋码，助力品质生产。',
                    route:'/selected-warehousing',
                    dataindex:'3'
                },
                {
                    index: 4,
                    url:require("../../assets/image/index/pc-banner-04-0626.png"),
                    title1:'',
                    title2:'',
                    title3:'',
                    route:'https://visualticket.cn/',
                    isLink: true,
                    // dataindex:'4'
                },
            ],
            flag:false,
            timer:null,
        };
    },

    mounted() {
        this.changImg();
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        hasLoaded(){
            console.log('图片加载完毕')
            this.headImgList[0].url  = require("../../assets/image/index/01-1大banner01.png")
        },
        jumpRout(route,index, isLink){
          // isLink 外链
          if (isLink) {
            route && window.open(route, '_blank');
            return;
          }
            this.activeIndex = index
            this.newsIndex = false
            // if(route,indexOf('news') != -1){
            //     this.newsIndex = true
            // }
            localStorage.setItem('headId',index);
            // localStorage.setItem('newsIndex',this.newsIndex);
            this.$router.push(route)
            this.activeIndex = localStorage.getItem('headId') || '1';
            this.refreshIndexInfo({
                data:this.activeIndex ,
                news:false
            });
            console.log("test store",this.$store.state);
        },
        changImg(){
            // console.log('timer',this.timer)
            console.log('timer1')
            // if(this.timer){
            //     clearTimeout(this.timer)
            //     this.timer = null
            // }
            this.flag = false
            this.timer = setTimeout(()=>{
                this.flag = true;
                clearTimeout(this.timer);
            },10)
            // let id = document.getElementById('carousel-img1')
            // id.style.transform = "scale("+ 1 +")"
            // id.style.transition = "all " + 3 + "s"
            // console.log(id.style)

        },
        nextImg(){
            console.log('timer2')
        }
    },
};
</script>

<style lang="less" scoped>
.carousel-img{
    width:100%;
    height:100%;
    transform: scale(1.2);
}
.carousel-img-small{
    animation-name: carImg; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 10s; // 动画完成时间
}
@keyframes carImg {
    0%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);
    }
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 1.8rem;
    opacity: 0.75;
    line-height: 30rem;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
/deep/ .el-carousel__indicator--horizontal{
    padding-bottom: 24rem;
}
/deep/ .el-carousel__indicators{
    margin-left: 1rem;
}
  .carousel-head{
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
    padding: 0 0 0.8rem 0;
    height: 15rem;
    border: darkgray 1px solid;
    .headContainerBackground{
        width: 100%;
        height: 50%;
        position: relative;
        overflow: hidden;
        padding: 1rem 1rem 0 1rem;
        border-radius: 0 0 50% 50%;
        background: #1ad17a;
    }
  }
  .carousel-head-content{
    position: absolute;
    margin: -70.6rem 0 0 47.6rem;
    z-index: 99;
    width: 96.7rem;
    opacity: 1;
    .title1{
        height: 4.8rem;
        font-size: 5rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 4.8rem;
        // transition: all 1s;
        opacity: 0;
    }
    .carousel-title-small{
        opacity: 1.0;
        transition: all 5s;
    }
    .title2{
        // width: 75.5rem;
        height: 4.8rem;
        font-size: 3.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 4.8rem;
        margin: auto;
        margin-top: 10rem;
        transform: scale(0);
    }
    .carousel-title-small2{
        transform: scale(1);
        transition: all 2s;
    }
    .title3{
        width: 96.7rem;
        height: 11.8rem;
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 5rem;
        opacity: 0;

        margin: auto;
        margin-top: 6.8rem;
    }
    .carousel-title-small3{
        opacity: 1;
        transition:2s  2s opacity;
        // animation-name: title3; // 动画名称
        // // animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
        // animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
        // animation-delay: 0s; // 动画延迟时间
        // // animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
        // animation-duration: 4s; // 动画完成时间
    }
  }
  @keyframes title3 {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
  .carousel-img1{
    position: absolute;
    z-index: 100;
    width: 4rem;
    height: 4rem;
    margin-top: -20rem;
    margin-left: -2rem;
    cursor: pointer;
  }
  .carousel-img2{
    position: absolute;
    z-index: 101;
    margin-left: -1.1rem;
    margin-top: -15rem;
    width: 2.1rem;
    height: 2.1rem;
    cursor: pointer;
  }
</style>
